<template>
  <div>
    <div v-if="loading" class="pt-4">
      <SpinnerLoader :loading="loading ? 'loading' : 'success'" />
    </div>
    <div v-else class="wrapper-default-page projects-table" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
      <div class="second__header mb-3 d-flex flex-wrap gap-2">
        <datepicker-wrapper
          v-model="selects.date_from"
          class="w-18p"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          size="sm"
          @input="selectedDateFrom"
        />
        <datepicker-wrapper
          v-model="selects.date_to"
          class="w-18p"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          size="sm"
          :min="selects.date_from"
          @input="loadProjectsList()"
        />
        <MultiSelect
          v-model="selects.channel"
          class="multiselect-sm w-18p"
          :options="filterChannelsList"
          :placeholder="$t('channelModal.channel')"
          label="name"
          track-by="id"
        />
        <MultiSelect
          v-model="selects.agency"
          class="multiselect-sm w-18p"
          :options="filterAgenciesList"
          :placeholder="$t('table.selectAgency')"
          label="name"
          track-by="id"
        />
        <MultiSelect
          v-model="selects.advertiser"
          class="multiselect-sm w-18p"
          :options="filterAdvertisersList"
          :placeholder="$t('table.selectAdvertiser')"
          label="name"
          track-by="id"
          :disabled="!(selects.agency && selects.agency.id)"
        />
        <MultiSelect
          v-model="selects.brand"
          class="multiselect-sm w-18p"
          :options="brandsListWithAll || []"
          :placeholder="$t('table.selectGroup')"
          label="name"
          track-by="id"
          :disabled="!(selects.advertiser && selects.advertiser.id)"
        />
        <MultiSelect
          v-model="selects.measurement"
          class="multiselect-sm w-18p"
          :options="measurementsList"
          :placeholder="$t('channelModal.measurements')"
          label="name"
          track-by="id"
        />
        <MultiSelect
          v-model="selects.target"
          class="multiselect-sm w-18p"
          :options="targetList"
          :placeholder="$t('channelModal.target')"
          label="name"
          track-by="id"
          :disabled="!(selects.measurement && selects.measurement.id)"
        />
        <!-- <MultiSelect
          class="multiselect-sm w-18p"
          v-model="selects.commercial"
          :options="commercialType"
          :placeholder="$t('table.selectCommercialType')"
          label="name"
          track-by="id"
        ></MultiSelect> -->
        <MultiSelect
          v-model="selects.type"
          class="multiselect-sm w-18p"
          :options="placementType"
          :placeholder="$t('table.selectType')"
          label="name"
          track-by="id"
        />
        <div class="w-18p d-flex gap-2">
          <b-input v-model.lazy="selects.mediaPlanId" type="number" size="sm" :placeholder="`${$t('table.mediaPlan')} ID`" min="0" @input="updateQuery()" />
          <b-button
            v-if="$checkPermissions('export.mediaplan')"
            size="sm"
            :disabled="!(selects.date_from && selects.date_to) || processing"
            :title="!(selects.date_from && selects.date_to) ? $t('table.selectDatesFirst') : ''"
            @click="exportPOM"
          >
            {{ processing ? $t('table.exporting') + '...' : $t('table.export') }}
          </b-button>
        </div>
      </div>

      <MediaPlansSummaryTable :media-plan-id-filter="selects.mediaPlanId" />
    </div>
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import convertDate from '@/filters/convertDate';
import MediaPlansSummaryTable from '@/components/MediaPlansSummary/MediaPlansSummaryTable';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';

export default {
  name: 'MediaPlansSummary',
  components: { SpinnerLoader, MultiSelect, MediaPlansSummaryTable, DatepickerWrapper },
  filters: {
    convertDate,
  },
  mixins: [downloadFileHandler],
  props: {
    //"commercial_type_id": { type: [String, Number], default: undefined },
    agency_id: { type: [String, Number], default: undefined },
    advertiser_id: { type: [String, Number], default: undefined },
    brand_id: { type: [String, Number], default: undefined },
    date_from: { type: String, default: undefined },
    date_to: { type: String, default: undefined },
    channel_id: { type: [String, Number], default: undefined },
    placement_type_id: { type: [String, Number], default: undefined },
    measurement_id: { type: [String, Number], default: undefined },
    target_id: { type: [String, Number], default: undefined },
    mediaplan_id: { type: [String, Number], default: undefined },
  },
  data() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().slice(0, 10);
    return {
      selects: {
        date_from: firstDay,
        date_to: lastDay,
        agency: '',
        commercial: '',
        channel: '',
        brand: '',
        advertiser: '',
        type: '',
        measurement: '',
        target: '',
        mediaPlanId: null,
      },
      filterAgenciesList: [],
      filterYearsList: [],
      filterAdvertisersList: [],
      filterChannelsList: [],
      loading: true,
      processing: false,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialType: 'getCommercialType',
      agenciesList: 'getAgenciesList',
      channels: 'getChannelsList',
      advertisersList: 'getAdvertisersList',
      advertisersStatus: 'getAdvertisersStatus',
      placementType: 'getPlacementType',
      brandsListWithAll: 'getBrandsListWithAll',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      sh_settings: 'getSalehousesSettings',
      selectedSaleshouse: 'getSelectedSaleshouse',
    }),
  },

  watch: {
    async 'selects.date_from'() {
      let year = this.selects.date_from.substr(0, 4);
      if (this.FEATURES.EQUALS) {
        if (year && !(this.sh_settings && this.sh_settings[0]?.year_id == year && this.selectedSaleshouse.id == this.sh_settings.saleshouse_id))
          this.$store.dispatch('GET_SALEHOUSES_SETTINGS', {
            //    id: 123,
            'filter[saleshouse_id]': this.selectedSaleshouse.id,
            'filter[year_id]': year,
          });
      }
    },

    // Filters
    async 'selects.agency'() {
      if (!this.loading) {
        if (this.selects.agency?.id) await this.loadAdvertisers();
        else this.filterAdvertisersList = [];

        if (
          (!this.selects.agency?.id && !this.selects.advertiser?.id) ||
          (this.selects.agency?.id && (!this.selects.advertiser?.id || this.selects.advertiser?.agencies?.find((e) => e.id === this.selects.agency?.id)))
        ) {
          await this.loadProjectsList();
        } else {
          this.selects.advertiser = '';
        }
      }
    },
    async 'selects.advertiser'() {
      if (!this.loading) {
        if (this.selects.advertiser?.id) await this.loadBrandsList();
        else this.$store.commit('clearBrandsList');

        if (
          (!this.selects.advertiser?.id && !this.selects.brand?.id) ||
          (this.selects.advertiser?.id && (!this.selects.brand?.id || this.selects.brand?.advertiser_id === this.selects.advertiser?.id))
        ) {
          await this.loadProjectsList();
        } else {
          this.selects.brand = '';
        }
      }
    },
    async 'selects.brand'() {
      if (!this.loading) await this.loadProjectsList();
    },
    async 'selects.commercial'() {
      if (!this.loading) await this.loadProjectsList();
    },
    async 'selects.channel'() {
      if (!this.loading) await this.loadProjectsList();
    },
    async 'selects.type'() {
      if (!this.loading) await this.loadProjectsList();
    },
    async 'selects.measurement'() {
      if (!this.loading) {
        if (this.selects.measurement) {
          await this.loadTargetsList();
        }
        if (this.selects.target.measurement_company_id !== this.selects.measurement?.id) this.selects.target = '';
      }
    },
    async 'selects.target'() {
      if (!this.loading) await this.loadProjectsList();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.mediaPlansSummary') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearBrandsList');
    this.$store.commit('clearMediaPlansSummary');
  },
  methods: {
    selectedDateFrom() {
      // Rewrite date TO if it is lower than FROM
      if (new Date(this.selects.date_from) > new Date(this.selects.date_to)) {
        this.selects.date_to = this.selects.date_from;
      }
      this.loadProjectsList();
    },
    async getData() {
      this.loading = true;
      //if (!this.commercialType || this.commercialType.length < 1) await this.$store.dispatch('GET_COMMERCIAL_TYPE');
      await Promise.all([
        this.placementType.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
        !this.agenciesList || this.agenciesList.length < 1 ? this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }) : undefined,
        !this.channels || this.channels.length < 1 ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
        this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
      ]);

      this.filterAgenciesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.agenciesList];
      this.filterChannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channels];

      await this.loadProps();
      this.loading = false;
    },

    async loadAdvertisers() {
      await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000, 'filter[agency_id]': this.selects.agency?.id, include: 'agencies' });
      this.filterAdvertisersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.advertisersList];
    },

    async loadBrandsList() {
      await this.$store.dispatch('GET_BRANDS', { 'filter[advertiser_id]': this.selects.advertiser?.id, per_page: 2000 });
    },

    async loadTargetsList() {
      await this.$store.dispatch('GET_TARGET', { 'filter[measurement_company_id]': this.selects.measurement?.id, per_page: 1000 });
    },

    async loadProjectsList() {
      await this.$store.dispatch('GET_MEDIA_PLANS_SUMMARY', {
        //commercial_type_id: this.selects.commercial?.id,
        agency_id: this.selects.agency?.id,
        advertiser_id: this.selects.advertiser?.id,
        brand_id: this.selects.brand?.id,
        date_from: this.selects.date_from,
        date_to: this.selects.date_to,
        channel_id: this.selects.channel?.id,
        placement_type_id: this.selects.type?.id,
        //measurement_id: this.selects.measurement?.id,
        target_audience_id: this.selects.target?.id,
      });
      this.updateQuery();
    },

    updateQuery() {
      setTimeout(() => {
        if (
          this.$route.query.commercial_type_id != this.selects.commercial?.id ||
          this.$route.query.agency_id != this.selects.agency?.id ||
          this.$route.query.advertiser_id != this.selects.advertiser?.id ||
          this.$route.query.brand_id != this.selects.brand?.id ||
          this.$route.query.date_from != this.selects.date_from ||
          this.$route.query.date_to != this.selects.date_to ||
          this.$route.query.channel_id != this.selects.channel?.id ||
          this.$route.query.placement_type_id != this.selects.type?.id ||
          this.$route.query.measurement_id != this.selects.measurement?.id ||
          this.$route.query.target_id != this.selects.target?.id ||
          this.$route.query.mediaplan_id != this.selects.mediaPlanId
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              commercial_type_id: this.selects.commercial?.id,
              agency_id: this.selects.agency?.id,
              advertiser_id: this.selects.advertiser?.id,
              brand_id: this.selects.brand?.id,
              date_from: this.selects.date_from,
              date_to: this.selects.date_to,
              channel_id: this.selects.channel?.id,
              placement_type_id: this.selects.type?.id,
              measurement_id: this.selects.measurement?.id,
              target_id: this.selects.target?.id,
              mediaplan_id: this.selects.mediaPlanId,
            },
          });
        }
      }, 50);
    },

    async loadProps() {
      if (this.mediaplan_id && /^\d+$/.test(this.mediaplan_id)) {
        this.selects.mediaPlanId = this.mediaplan_id;
      }
      if (this.agency_id && /^\d+$/.test(this.agency_id)) {
        this.selects.agency = this.agenciesList.find((el) => el.id === +this.agency_id) || '';
        if (this.selects.agency) {
          await this.loadAdvertisers();
          if (this.advertiser_id && /^\d+$/.test(this.advertiser_id)) {
            this.selects.advertiser = this.advertisersList.find((el) => el.id === +this.advertiser_id) || '';
            if (this.selects.advertiser) {
              await this.loadBrandsList();
              if (this.brand_id && /^\d+$/.test(this.brand_id)) {
                this.selects.brand = this.brandsListWithAll.find((el) => el.id === +this.brand_id) || '';
              }
            }
          }
        }
      }
      // if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
      //   this.selects.commercial = this.commercialType.find(el => el.id === +this.commercial_type_id) || '';
      // }
      if (this.placement_type_id && /^\d+$/.test(this.placement_type_id)) {
        this.selects.type = this.placementType.find((el) => el.id === +this.placement_type_id) || '';
      }
      if (this.date_from && /\d{4}-\d{2}-\d{2}/.test(this.date_from)) this.selects.date_from = this.date_from;
      if (this.date_to && /\d{4}-\d{2}-\d{2}/.test(this.date_to)) this.selects.date_to = this.date_to;
      if (this.channel_id && /^\d+$/.test(this.channel_id)) {
        this.selects.channel = this.channels.find((el) => el.id === +this.channel_id) || '';
      }
      if (this.measurement_id && /^\d+$/.test(this.measurement_id)) {
        this.selects.measurement = this.measurementsList.find((el) => el.id === +this.measurement_id) || '';
        if (this.selects.measurement) {
          await this.loadTargetsList();
          if (this.target_id && /^\d+$/.test(this.target_id)) {
            this.selects.target = this.targetList.find((el) => el.id === +this.target_id) || '';
          }
        }
      }
      await this.loadProjectsList();
    },

    async exportPOM() {
      this.processing = true;
      await this.$store.dispatch('GET_MEDIA_PLANS_SUMMARY_EXPORT', {
        data: {
          format: 'xlsx',
          agency_id: this.selects.agency?.id,
          advertiser_id: this.selects.advertiser?.id,
          brand_id: this.selects.brand?.id,
          date_from: this.selects.date_from,
          date_to: this.selects.date_to,
          channel_id: this.selects.channel?.id,
          target_audience_id: this.selects.target?.id,
          placement_type_id: this.selects.type?.id,
          //commercial_type_id: this.selects.commercial?.id,
        },
        handler: (res) => {
          this.prepareAndDownloadFile(res);
        },
      });
      this.processing = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.wrapper-default-page.projects-table
  padding: 70px 16px 0
.w-18p
  width: 18% !important
  max-width: 18% !important
</style>
