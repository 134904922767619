<template>
  <div>
    <SpinnerLoader v-if="mediaPlansSummaryStatus !== 'success'" :loading="mediaPlansSummaryStatus" />
    <template v-else-if="mediaPlansSummaryStatus === 'success' && mediaPlansSummary && mediaPlansSummaryFiltered">
      <virtual-scroll ref="virtualScroll" :items="mediaPlansSummaryFiltered.items" :item-height="27" class="vscroll table-responsive mb-0 mp-summary-wrapper">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-mp-summary table-bordered ws-nowrap sticky-footer sticky-headers">
            <thead>
              <tr>
                <th>
                  <div class="resizable agency">{{ $t('table.agency') }}</div>
                </th>
                <th>
                  <div class="resizable advertiser">{{ $t('table.advertiser') }}</div>
                </th>
                <th>
                  <div class="resizable project">{{ $t('table.project') }}</div>
                </th>
                <th>
                  <div class="resizable order">{{ $t('table.order') }}</div>
                </th>
                <th>
                  <div class="resizable media_plan">{{ $t('table.mediaPlan') }}</div>
                </th>
                <th>
                  <div class="resizable brand">{{ $t('table.brand') }}</div>
                </th>
                <th>
                  <div class="resizable channel">{{ $t('channelModal.channel') }}</div>
                </th>
                <th>
                  <div class="resizable target">{{ $t('channelModal.target') }}</div>
                </th>
                <th>{{ $t('channelModal.from') }}</th>
                <th>{{ $t('channelModal.to') }}</th>
                <th>
                  <div class="resizable type">{{ $t('table.type') }}</div>
                </th>
                <th>
                  <div class="resizable status">{{ $t('table.status') }}</div>
                </th>
                <th class="text-right">{{ $t('table.placementCount') }}</th>
                <th class="text-right">{{ $t('table.secCount') }}</th>
                <th class="text-right">{{ $t('table.commitment') }} {{ show_equals ? 'Equals' : 'wGRP' }}</th>
                <th class="text-right">{{ $t('table.plan') }} {{ show_equals ? 'Equals' : 'wGRP' }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }} {{ show_equals ? 'Equals' : 'wGRP' }}</th>
                <th class="text-right">{{ $t('table.fact') }} {{ show_equals ? 'Equals' : 'wGRP' }}</th>
                <th class="text-right">{{ $t('table.discount') }}(-)/<br />{{ $t('table.surcharge') }}(+)</th>
                <th class="text-right">{{ $t('table.commitment') }}</th>
                <th class="text-right">{{ $t('table.plan') }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
                <th class="text-right">{{ $t('table.fact') }}</th>
                <th class="text-right">{{ $t('table.delta') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.mediaplan_id">
                <td name="agency" :title="item.agency_name">{{ item.agency_name }}</td>
                <td name="advertiser" :title="item.advertiser_name">{{ item.advertiser_name }}</td>
                <td name="project">
                  <a :href="`/projects?project_id=${item.project_id}`" target="_blank" rel="noopener noreferrer" :title="item.project_name">{{
                    item.project_name
                  }}</a>
                </td>
                <td name="order">
                  <a
                    :href="`/projects?project_id=${item.project_id}&order_id=${item.order_id}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    :title="item.order_name"
                  >
                    {{ item.order_name }}
                  </a>
                </td>
                <td name="media_plan">
                  <a
                    :href="`/projects?project_id=${item.project_id}&order_id=${item.order_id}&mediaplan_id=${item.mediaplan_id}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    :title="item.mediaplan_name"
                  >
                    <template v-if="showSummaryMpID"> [{{ item.mediaplan_id }}]</template>
                    {{ item.mediaplan_name }}
                  </a>
                </td>
                <td name="brand" :title="item.brand_name">{{ item.brand_name }}</td>
                <td name="channel" :title="item.channel_name">{{ item.channel_name }}</td>
                <td name="target" :title="item.target_audience_name">{{ item.target_audience_name }}</td>
                <td class="ws-nowrap">{{ item.mediaplan_date_from | convertDate }}</td>
                <td class="ws-nowrap">{{ item.mediaplan_date_to | convertDate }}</td>
                <td name="type">{{ item.placement_type_name }}</td>
                <td name="status">{{ item.is_closed ? $t('table.closed') : $t('table.open') }}</td>
                <td class="text-right">{{ item.spot_count }}</td>
                <td class="text-right">{{ item.seconds_total }}</td>
                <td class="text-right">{{ item.commitment_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_fact_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.fact_wgrp | toFixedAndSpace }}</td>
                <td class="ws-nowrap text-right">{{ item.discount ? (+item.discount).toFixed(2) + '%' : '' }}</td>
                <td class="text-right">{{ item.commitment_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_fact_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.fact_sum | toFixedAndSpace }}</td>
                <td class="ws-nowrap text-right">{{ item.delta.toFixed(2) }} %</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold">
              <tr>
                <td>{{ $t('booking.total') }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.spot_count }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.seconds_total }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.commitment_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.plan_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.plan_fact_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.fact_wgrp | toFixedAndSpace }}</td>
                <td></td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.commitment_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.plan_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.plan_fact_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ mediaPlansSummaryFiltered.totals.fact_sum | toFixedAndSpace }}</td>
                <td class="ws-nowrap text-right">{{ mediaPlansSummaryFiltered.totals.delta.toFixed(2) }} %</td>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import convertDate from '@/filters/convertDate';
import VirtualScroll from '@/components/VirtualScroll';
import toFixedAndSpace from '@/filters/toFixedAndSpace';

export default {
  name: 'MediaPlansSummaryTable',
  components: { SpinnerLoader, VirtualScroll },
  filters: { convertDate, toFixedAndSpace },
  props: {
    mediaPlanIdFilter: { type: String, default: undefined, required: false },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      mediaPlansSummary: 'getMediaPlansSummary',
      mediaPlansSummaryStatus: 'getMediaPlansSummaryStatus',
      showSummaryMpID: 'getShowSummaryMpID',
      sh_settings: 'getSalehousesSettings',
      selectedSaleshouse: 'getSelectedSaleshouse',
    }),
    show_equals() {
      if (this.FEATURES.EQUALS) {
        return this.sh_settings[0]?.is_equals_enabled ?? false;
      } else {
        return false;
      }
    },
    mediaPlansSummaryFiltered() {
      if (!(this.mediaPlansSummaryStatus === 'success' && this.mediaPlansSummary)) return null;
      if (this.mediaPlanIdFilter)
        return {
          items: this.mediaPlansSummary.items.filter((m) => m.mediaplan_id.toString().includes(this.mediaPlanIdFilter)),
          totals: this.mediaPlansSummary.totals,
        };
      else return this.mediaPlansSummary;
    },
  },
  watch: {
    mediaPlanIdFilter() {
      setTimeout(() => {
        this.$refs.virtualScroll?.updateWindow();
      }, 500);
    },
  },
  destroyed() {
    this.$store.commit('clearMediaPlansSummary');
  },
  methods: {},
};
</script>

<style lang="sass">
@use '~/src/assets/sass/_variables' as *

div.vscroll.table-responsive.mp-summary-wrapper
  height: calc(100vh - 151px)

.reports-page div.table-responsive.mp-summary-wrapper
  height: calc(100vh - 171px)

.reports-page.hiddenFilters div.table-responsive.mp-summary-wrapper
  height: calc(100vh - 98px)

table.table-mp-summary
  td, th
    padding: 4px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  div.resizable
    overflow: auto
    overflow-x: hidden
    text-overflow: ellipsis

    &.channel,
    &.agency,
    &.advertiser,
    &.brand,
    &.status,
    &.type,
    &.target
      min-width: 70px
      width: 70px
    &.project,
    &.order,
    &.media_plan
      min-width: 100px
      width: 100px

  thead:hover div.resizable
    resize: horizontal

  td[name="channel"],
  td[name="agency"],
  td[name="advertiser"],
  td[name="brand"],
  td[name="status"],
  td[name="type"],
  td[name="target"]
    max-width: 70px
    min-width: 70px
  td[name="project"],
  td[name="order"],
  td[name="media_plan"]
    max-width: 100px
    min-width: 100px
</style>
